.root {
  margin-top: 100px;

  .buttons {
    .editButton {
      margin-right: 20px;
    }
  }

  .title {
    margin-top: 20px;
    font-size: x-large;
  }

  .description {
    margin-top: 20px;
  }
}